@media print {
  .container_auto {
    page-break-before: always;
  }
}

@media print {
  .container_4_6 {
    max-height: calc(6in - 10px);
    page-break-before: always;
  }
}
@media print {
  .container_3_5 {
    max-height: calc(5in - 10px);
    page-break-before: always;
  }
}

.container_4_6 {
  width: calc(4in - 10px);
  flex-direction: column;
  margin: 0 auto;
}

/* Logo container styles */
.logo-container_4_6 {
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  height: 65px;
  width: calc(4in - 10px);
  border: 1px solid black;
  border-bottom: none;
  align-items: center;
}
.grid-item_4_6:first-child {
  border-right: 1px solid black;
}

/* Logo image styles */
.logo-container_4_6 img {
  object-fit: contain;
  height: 60px;
  width: 140px;
  margin: 0 auto;
}

/* Ship To and Address container styles */
.ship-to-container_4_6 {
  padding: 0.25rem;
  overflow: hidden;
  width: calc(4in - 10px);
  font-size: 12px;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
  height: 88px;
  line-height: 1.3;
}
.ship-to-text_4_6 {
  color: black;
  text-transform: capitalize;
}

/* Order details and other details container styles */
.details-container_4_6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  overflow: hidden;
  justify-content: center;
  width: calc(4in - 10px);
  border: 1px solid black;
  height: 78px;
}

.order-details_4_6 {
  grid-column: span 4;
  padding: 0.25rem;
  border-right: 1px solid black;
  font-size: 12px;
}

.order-text_4_6 {
  color: black;
  font-weight: bold;
}
.other-details-container_4_6 {
  grid-column: span 2;
  vertical-align: top;
  font-size: 12px;
  text-align: center;
  // padding: 0.25rem;
  margin: 0 auto;
}

.other-details-text_4_6 {
  color: black;
  font-weight: bold;
  text-transform: capitalize;
}

/* Seller details and product details container styles */

.seller-details_4_6 {
  padding: 0.25rem;
  overflow: hidden;
  width: calc(4in - 10px);
  font-size: 12px;
  height: 50px;
  line-height: 1.1;
  border: 1px solid black;
  border-top: none;
}

/* Product details text styles */

.prodDetails_4_6 {
  padding: 0.25rem;
  overflow: hidden;
  width: calc(4in - 10px);
  font-size: 10px;
  height: 108px;
  line-height: 1.1;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
}

/* Barcode and quantity container styles */

.barcode-container_4_6 {
  border: 1px solid black;
  height: 65px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  width: calc(4in - 10px);
}
.barcode-container-bar_4_6 {
  justify-content: center;
  height: 65px;
  overflow: hidden;
  align-items: center;
}
.quantity-container_4_6 {
  grid-column: span 2;
  text-align: center;
  padding-top: 0.5rem;
}

/* Return address container styles */

.return-address-container_4_6 {
  padding: 0.25rem;
  overflow: hidden;
  width: calc(4in - 10px);
  font-size: 12px;
  height: 50px;
  line-height: 1.1;
  border: 1px solid black;
  border-top: none;
}

/* Return address text styles */
.return-address-text_4_6 {
  color: black;
}
///________________________     auto

.container_auto {
  width: calc(4in - 10px);
  flex-direction: column;
}

/* Logo container styles */
.logo-container_auto {
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  width: calc(4in - 10px);
  height: 65px;
  border: 1px solid black;
  border-bottom: none;
  align-items: center;
}
.grid-item_auto:first-child {
  border-right: 1px solid black;
}

/* Logo image styles */
.logo-container_auto img {
  object-fit: contain;
  height: 60px;
  width: 140px;
  margin: 0 auto;
}

/* Ship To and Address container styles */
.ship-to-container_auto {
  padding: 0.25rem;
  overflow: hidden;
  width: calc(4in - 10px);
  font-size: 12px;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
  height: 88px;
  line-height: 1.3;
}
.ship-to-text_auto {
  color: black;
  text-transform: capitalize;
}

/* Order details and other details container styles */
.details-container_auto {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  overflow: hidden;
  justify-content: center;
  width: calc(4in - 10px);
  border: 1px solid black;
  height: 78px;
}

.order-details_auto {
  grid-column: span 4;
  padding: 0.25rem;
  border-right: 1px solid black;
  font-size: 12px;
}

.order-text_auto {
  color: black;
  font-weight: bold;
}
.other-details-container_auto {
  grid-column: span 2;
  vertical-align: top;
  font-size: 12px;
  text-align: center;
  // padding: 0.25rem;
  margin: 0 auto;
}

.other-details-text_auto {
  color: black;
  font-weight: bold;
  text-transform: capitalize;
}

/* Seller details and product details container styles */

.seller-details_auto {
  padding: 0.25rem;
  overflow: hidden;
  width: calc(4in - 10px);
  font-size: 12px;
  height: 50px;
  line-height: 1.1;
  border: 1px solid black;
  border-top: none;
}

/* Product details text styles */

.prodDetails_auto {
  padding: 0.25rem;
  overflow: hidden;
  width: calc(4in - 10px);
  font-size: 10px;
  height: 108px;
  line-height: 1.1;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
}

/* Barcode and quantity container styles */

.barcode-container_auto {
  border: 1px solid black;
  height: 65px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  width: calc(4in - 10px);
}
.barcode-container-bar_auto {
  justify-content: center;
  height: 65px;
  overflow: hidden;
  align-items: center;
}
.quantity-container_auto {
  grid-column: span 2;
  text-align: center;
  padding-top: 0.5rem;
}

/* Return address container styles */

.return-address-container_auto {
  padding: 0.25rem;
  overflow: hidden;
  width: calc(4in - 10px);
  font-size: 12px;
  height: 50px;
  line-height: 1.1;
  border: 1px solid black;
  border-top: none;
}

/* Return address text styles */
.return-address-text_auto {
  color: black;
}
//__________________________________ 3_5
.container_3_5 {
  height: calc(5in - 10px);
  flex-direction: column;
  margin: 0 auto;
}

/* Logo container styles */
.logo-container_3_5 {
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  width: calc(3in - 10px);
  height: 40px;
  border: 1px solid black;
  border-bottom: none;
  align-items: center;
  justify-content: center;
}
// .grid-item_3_5 {
//   height: 45px;
// }
.grid-item_3_5:first-child {
  border-right: 1px solid black;
}

/* Logo image styles */
.logo-container_3_5 img {
  object-fit: contain;
  height: 30px;
  margin: 0 auto;
}

/* Ship To and Address container styles */
.ship-to-container_3_5 {
  padding: 0.18rem;
  overflow: hidden;
  width: calc(3in - 10px);
  font-size: 11.5px;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
  height: 76.5px;
  line-height: 1.2;
}
.ship-to-text_3_5 {
  color: black;
}

/* Order details and other details container styles */
.details-container_3_5 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  overflow: hidden;
  justify-content: center;
  width: calc(3in - 10px);
  border: 1px solid black;
  height: 65px;
}

.order-details_3_5 {
  grid-column: span 4;
  padding: 0.1rem;
  border-right: 1px solid black;
  font-size: 10.5px;
  line-height: 1.1;
}

.order-text_3_5 {
  color: black;
  font-weight: bold;
}
.other-details-container_3_5 {
  grid-column: span 2;
  vertical-align: top;
  font-size: 9.5px;
  text-align: center;
  margin: 0 auto;
}
.other-details-text_3_5 {
  color: black;
  font-weight: bold;
  text-transform: capitalize;
}

/* Seller details and product details container styles */

.seller-details_3_5 {
  border: 1px solid black;
  padding: 0.1rem;
  overflow: hidden;
  width: calc(3in - 10px);
  font-size: 10.5px;
  height: 50px;
  line-height: 1.1;
}

/* Product details text styles */

.prodDetails_3_5 {
  padding: 0.15rem;
  overflow: hidden;
  width: calc(3in - 10px);
  font-size: 10px;
  height: 108px;
  line-height: 1.1;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
}

/* Barcode and quantity container styles */

.barcode-container_3_5 {
  border: 1px solid black;
  height: 50px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  width: calc(3in - 10px);
  align-items: center;
}
.barcode-container-bar_3_5 {
  justify-content: center;
  height: 48px;
  overflow: hidden;
  align-items: center;
}
.quantity-container_3_5 {
  grid-column: span 2;
  text-align: center;
}

/* Return address container styles */

.return-address-container_3_5 {
  padding: 0.1rem;
  overflow: hidden;
  width: calc(3in - 10px);
  font-size: 10.5px;
  height: 50px;
  line-height: 1.1;
  border: 1px solid black;
  border-top: none;
}

/* Return address text styles */
.return-address-text_3_5 {
  color: black;
}

// manifest
.manifiest-table-container_manifest {
  margin: 10mm 10mm 10mm 10mm;
  white-space: normal !important;
  width: 80%;

  table {
    border-spacing: 0px;
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    margin-left: auto;
    margin-right: auto;
  }

  th {
    color: green;
    border: 1px solid black;
  }

  td {
    border: 1px solid black;
    word-wrap: break-word;
  }
}
@media print {
  .pagebreak_manifest {
    page-break-before: always;
  }
  .manifiest-table-container_manifest {
    margin: 10mm 10mm 10mm 10mm;
    white-space: normal !important;
    width: 80%;

    table {
      border-spacing: 0px;
      table-layout: fixed;
      width: 100%;
      border-collapse: collapse;
      margin-left: auto;
      margin-right: auto;
    }

    th {
      color: green;
      border: 1px solid black;
    }

    td {
      border: 1px solid black;
      word-wrap: break-word;
    }
  }
}
