.calendarElement {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid black;
  z-index: 999;
  display: flex;
}
@media screen and (max-width: 767px) {
  .calendarElement {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 400px;
    overflow: scroll;
  }
}
