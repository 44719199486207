.glassUI {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
.glassUI::before {
  content: "_";
  display: block;
  background-color: #274a69;
  height: 300px;
  z-index: 1;
  color: #274a69;
  border-radius: 8px;
  position: absolute;
  left: -0.3%;
}
